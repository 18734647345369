<template>
  <div class="page-container">
    <v-card width="1170" class="login-card">
      <div :class="`info-block ${role}-bg`"></div>
      <div class="login-form">
        <h2 class="title">Your Path to Success Online Starts Here.</h2>
        <span class="description"
          >Whether you’re looking to start, scale, or expand your business reach, NFTIZ can help.</span
        >

        <radio-button-cards
          :items="items"
          width="150px"
          height="150px"
          v-model="role"
          class="mb-5"
        >
          <template v-slot:default="{ item }">
            <v-icon class="mx-auto" size="80">{{ item.icon }}</v-icon>

            <h4>{{item.text}}</h4>
          </template>
        </radio-button-cards>

        <v-btn color="primary" width="250" @click="connect" :disabled="!role">Connect Wallet</v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mdiAlphaABox, mdiAlphaPBox } from '@mdi/js'

import { mapGetters } from 'vuex'

import RadioButtonCards from '@/components/ui/radio-button-cards/RadioButtonCards'
import AppBarUserMenu from '@/layouts/components/app-bar/AppBarUserMenu.vue'
import { ADVERTISER, PUBLISHER } from '@/utils/constants'

import { validateUserByRole } from '@/utils/user'

export default {
  components: {
    RadioButtonCards,
    AppBarUserMenu,
  },
  created() {
    if (this.$route.query.role) {
      this.role = this.$route.query.role
    }
  },
  data: () => ({
    items: [
      { value: ADVERTISER, text: 'Advertiser', icon: mdiAlphaABox },
      { value: PUBLISHER, text: 'Publisher', icon: mdiAlphaPBox },
    ],
    role: null,
  }),
  computed: {
    ...mapGetters('web3auth', ['userdata']),
  },
  methods: {
    async connect() {
      await this.$store.dispatch('web3auth/CONNECT', { auto: false, role: this.role })

      if (this.userdata) {
        const valid = validateUserByRole(this.userdata, this.role)
        const route = valid ? this.role : `${this.role}-onboarding`

        this.$router.push({ name: route })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  width: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-card {
  display: flex;
}

.info-block {
  width: 50%;
  background-image: url('../../assets/images/content/rocket.gif');
  background-size: 300px;
  background-position: center;
}

.advertiser-bg {
  background-image: url('../../assets/images/content/rocket_advertiser.gif');
}
.publisher-bg {
  background-image: url('../../assets/images/content/rocket_publisher.gif');
}

.login-form {
  width: 50%;
  padding: 15px;
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
}

h2.title {
  color: var(--v-primary-base) !important;
  font-size: 26px !important;
  font-weight: 600;
  max-width: 300px;
  margin: 0 auto;
  display: block;
  margin-bottom: 5px;
}

.description {
  font-weight: 600;
  max-width: 80%;
  margin: 0 auto;
  display: block;
  text-align: center;
  margin-bottom: 30px;
}

@media screen and (max-width: 800px) {
  .login-card {
    flex-direction: column-reverse;
  }

  .info-block {
    display: none;
  }

  .login-form {
    width: 100%;
  }
}
</style>
